<script lang="ts" setup>
import Arrow from "@/assets/icons/arrow.svg?component";
import { useSwiper } from "swiper/vue";

const props = withDefaults(
  defineProps<{
    type?: "primary" | "secondary";
    variation?: string;
    swiperController?: ReturnType<typeof useSwiper>;
    items?: Array<{ fallback_color: string }>;
  }>(),
  {
    type: "primary",
  }
);

const swiper = props.swiperController ?? useSwiper();
const fallbackColor = computed(
  () => props.items?.[swiper.value.activeIndex]?.fallback_color
);
</script>
<template>
  <div class="slider-controls grid grid-cols-2 gap-2 z-10">
    <base-button
      only-icon
      transparent
      @click="swiper.slidePrev()"
      :disabled="swiper.activeIndex === 0"
      class="hover:border-integer-white"
      :class="{
        'border-integer-black text-integer-black hover:border-integer-black hover:text-integer-white hover:bg-integer-black':
          fallbackColor === 'white' ||
          fallbackColor === 'gray' ||
          fallbackColor === 'green',
        'border-integer-white text-integer-white hover:border-integer-white hover:text-integer-black hover:bg-integer-white':
          variation === 'Dark',
        'border-integer-white':
          fallbackColor === 'black',
      }"
      :type="type"
    >
      <Arrow class="rotate-180" />
    </base-button>
    <base-button
      only-icon
      transparent
      @click="swiper.slideNext()"
      :disabled="swiper.isEnd"
      class="hover:border-integer-white"
      :class="{
        'border-integer-black text-integer-black hover:border-integer-black hover:text-integer-white hover:bg-integer-black':
          fallbackColor === 'white' ||
          fallbackColor === 'gray' ||
          fallbackColor === 'green',
        'border-integer-white text-integer-white hover:border-integer-white hover:text-integer-black hover:bg-integer-white':
          variation === 'Dark',
        'border-integer-white':
          fallbackColor === 'black',
      }"
      :type="type"
    >
      <Arrow />
    </base-button>
  </div>
</template>
